import React, {useEffect, useRef} from "react";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import {observer} from "mobx-react-lite";
import {authStore} from "../../store/authStore";
import {cacheService} from "../../services/cacheService";
import {unityStore} from "../../store/unityStore";
import {tr} from "../../helpers/translations/tr";

const DoubleLogin = observer(() => {
    const { setDoubleLogin } = authStore;
    const {setLoadUnity} = unityStore;
    const ref = useRef();

    const onUserNavigateForLogin = () => {
        const language = cacheService.get("Language");
        cacheService.clear();
        cacheService.set("Language", language);
        window.location.assign("/");
    };

    const handleClose = () => {
        setDoubleLogin(false);
        onUserNavigateForLogin();
    }

    useOnClickOutside(ref, () => handleClose(false));

    useEffect(() => {
        setLoadUnity(false);
        setTimeout(handleClose, 5000)
    }, [])

    return (
        <div className="double-login-modal">
            <div className="double-login-popup" ref={ref}>
                <span className="icon-close" onClick={handleClose}></span>
                <p className="double-login-description">
                    {tr("you_can_use")}
                </p>
                <span className="ok" onClick={handleClose}>{tr("ok")}</span>
            </div>
        </div>
    );
});

export default DoubleLogin;
