import {makeObservable, observable, action, runInAction} from "mobx";
import {cacheService} from "../services/cacheService";
import apiServices from "../services/apiServices";
import {Languages} from "../constants";
import {loadingStore} from "./loadingStore";
import translationData from "./translationData.json";

class TranslationStore {
    activeLanguage = Languages[cacheService.get("Language")]?.title || Languages["ENG"].title;
    translationData = translationData;
    allTranslates = {};

    constructor() {
        makeObservable(this, {
            activeLanguage: observable,
            translationData: observable,
            allTranslates: observable,
            setActiveLanguage: action.bound,
            getTranslationData: action.bound,
            setAllTranslates: action.bound,
        });
    }

    setActiveLanguage(data) {
        this.activeLanguage = data.title;
        cacheService.set("Language", data.title);
    }

    setTranslationData(data) {
        this.translationData = {
            ...this.translationData,
            ...data
        };
    }

    setAllTranslates(data, lang) {
        this.allTranslates = {
            ...this.allTranslates,
            [lang]: data
        }
    }

    getTranslationData = async () => {
        try {
            const { value, title } = Languages[this.activeLanguage]
            loadingStore.toggleTranslateLoading(true);
            if (!this.allTranslates.hasOwnProperty(title)) {
                if (!cacheService.get("Language")) {
                    cacheService.set("Language", Languages['ENG'].title);
                    this.activeLanguage = 'ENG';
                }
                const { data: { data } } = await apiServices.getTranslation(value || 'eng')
                document.documentElement.lang = value || 'eng'
                runInAction(() => {
                    this.setAllTranslates(data, title)
                    this.setTranslationData(data)
                })
            } else {
                this.setTranslationData(this.allTranslates[title])
            }
        } catch (e) {
            console.warn("ERROR: ", e.message);
        } finally {
            loadingStore.toggleTranslateLoading(false);
        }
    }


}

export const translationStore = new TranslationStore();